export default [
    {
        component: 'CNavItem',
        name: 'Panel',
        access: 'panel',
        to: '/panel',
        icon: 'fas fa-solar-panel',
    },
    {
        component: 'CNavGroup',
        name: 'Tareas',
        access: 'Tareas',
        to: '',
        icon: 'fas fa-magnifying-glass-arrow-right',
        items: [
            {
                component: 'CNavItem',
                name: 'Listado de tareas',
                access: 'tareas/mis-tareas',
                to: '/task-list',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Administración',
        access: 'Administración',
        to: '',
        icon: 'fas fa-cogs',
        items: [
            {
                component: 'CNavItem',
                name: 'Flujos',
                access: 'admin/flujos',
                to: '/flow-editor',
            },
            {
                component: 'CNavItem',
                name: 'Conexiones',
                access: 'admin/connections',
                to: '/connections/list',
            },
            {
                component: 'CNavItem',
                name: 'Alertas',
                access: 'admin/alerts',
                to: '/alerts/list',
            },
            /*{
                component: 'CNavItem',
                name: 'Páginas',
                access: 'admin/paginas',
                to: '/admin/blog/list',
            },
            {
                component: 'CNavItem',
                name: 'Páginas de ayuda',
                access: 'admin/paginas-ayuda',
                to: '/admin/ayuda/list',
            },*/
            /*{
                component: 'CNavItem',
                name: 'Plantillas PDF',
                access: 'admin/plantillas-pdf',
                to: '/admin/plantillas-pdf',
            },*/
            {
                component: 'CNavItem',
                name: 'Variables de sistema',
                access: 'admin/system-vars',
                to: '/admin/system-vars',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Usuarios',
        to: '',
        icon: 'fas fa-users',
        access: 'Usuarios',
        items: [
            {
                component: 'CNavItem',
                access: 'users/admin',
                name: 'Administrar usuarios',
                to: '/usuarios/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/admin/grupos',
                name: 'Canales de usuarios',
                to: '/usuarios/canal/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/admin/grupos',
                name: 'Grupos de usuarios',
                to: '/usuarios/grupo/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/role/admin',
                name: 'Administrar roles',
                to: '/usuarios/roles/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/jerarquia/admin',
                name: 'Configurar supervisión',
                to: '/usuarios/jerarquia/listado',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Paneles',
        access: 'paneles/listado',
        to: '',
        icon: 'fas fa-chart-line',
        items: [
            {
                component: 'CNavItem',
                name: 'Configuración de paneles',
                access: 'paneles/config',
                to: '/paneles/config',
            },
            {
                component: 'CNavItem',
                name: 'Mis paneles',
                access: 'paneles/listado',
                to: '/paneles/listado',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Reportes',
        access: 'reportes',
        to: '',
        icon: 'fas fa-chart-pie',
        items: [
            {
                component: 'CNavItem',
                name: 'Mis consumos',
                access: 'reportes/mis-consumos',
                to: '/reportes/mis-consumos',
            },
            {
                component: 'CNavItem',
                name: 'Generar reporte',
                access: 'reportes/generar',
                to: '/reportes/generar',
            },
            {
                component: 'CNavItem',
                name: 'Descarga de documentos',
                access: 'reportes/download-docs',
                to: '/reportes/download-docs',
            },
            {
                component: 'CNavItem',
                name: 'Configuración',
                access: 'reportes/admin',
                to: '/reportes/configuracion',
            },
        ],
    },
    /*{
        component: 'CNavGroup',
        name: 'Configuración',
        access: 'Configuración',
        to: '',
        icon: 'fas fa-cog',
        items: [
            {
                component: 'CNavItem',
                access: 'configuration',
                name: 'Administrar',
                to: '/configuration/sistema',
            },
        ],
    },*/
]
