import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
//import DocsExample from '@/components/DocsExample'
import 'floating-vue/dist/style.css'

import Vue3EasyDataTable from 'vue3-easy-data-table';

import {
    // Directives
    vTooltip,
    vClosePopper,
    // Components
    Dropdown,
    Tooltip,
    Menu
} from 'floating-vue'


import 'vue3-easy-data-table/dist/style.css';
import "toastify-js/src/toastify.css";
import "@vueform/multiselect/themes/default.css";
import { VueSignaturePad } from 'vue-signature-pad';
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('EasyDataTable', Vue3EasyDataTable);
app.component("VueSignaturePad", VueSignaturePad);

app.directive('tooltip', vTooltip)
app.directive('close-popper', vClosePopper)

app.component('VDropdown', Dropdown)
app.component('VTooltip', Tooltip)
app.component('VMenu', Menu)

app.mount('#app')
