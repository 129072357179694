<template>
    <CSidebar
        position="fixed"
        :unfoldable="sidebarUnfoldable"
        :visible="sidebarVisible"
        @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
    >
        <CSidebarBrand>
            <img :src="logoNegative" class="sidebar-brand-full">
        </CSidebarBrand>
        <div class="sidebarNotifyBox">
            <div class="notifiBadge" @click="$router.push('/task-list')">
                <div class="row">
                    <div class="col-3 text-center">
                        <div>
                            <i class="fa fa-tasks"></i>
                        </div>
                    </div>
                    <div class="col-7">
                        Ir a tareas
                    </div>
                </div>
            </div>
        </div>
        <ContainerMenu/>
<!--        <CSidebarToggler
            class="d-none d-lg-flex"
            @click="$store.commit('toggleUnfoldable')"
        />-->
    </CSidebar>
</template>

<script>
import {computed} from 'vue'
import {mapGetters, useStore} from 'vuex'
import ContainerMenu from './ContainerMenu.vue'
import logoNegative from '@/assets/images/logo.png'
import {sygnet} from '@/assets/brand/sygnet'
import {routeLocationKey} from "vue-router";

export default {
    name: 'AppSidebar',
    computed: {
        routeLocationKey() {
            return routeLocationKey
        }
    },
    components: {
        ContainerMenu,
    },
    setup() {
        const store = useStore()
        return {
            logoNegative,
            sygnet,
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
        }
    },

}
</script>
