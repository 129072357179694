<template>
  <router-view />
</template>

<!--Test comentario-->

<style lang="scss">
// Import Main styles for this application
@import 'assets/css/fontawesome.css';
@import 'assets/css/bootstrap.css';
@import 'styles/style';
</style>
