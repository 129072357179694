import store from "@/store";
import {config} from "/src/config";
import Toastify from 'toastify-js'
import Swal from "sweetalert2";

export default {
    doAjax: (method, url, data, onSuccess, onError, options, showLoading = true) => {

        if (!method) method = 'GET';
        method = method.toUpperCase();

        if (!options) options = {};

        let isFile = false;

        if (method === 'FILE') {
            method = 'POST';
            isFile = true;
        }

        /*if (!options.disableLoading) {
            // voy a validar si el token esta ok
            store.dispatch('coreShowLoading');
        }*/
        if(showLoading) store.dispatch('showLoading');

        const token = (typeof store.getters.authInfo !== 'undefined' && typeof store.getters.authInfo.token !== 'undefined') ? store.getters.authInfo.token : '';

        let contentType = 'application/json';

        const headers = {
            'Authorization': 'Bearer ' + token,
        };

        if (!isFile) {
            headers["Content-Type"] = contentType;
        }

        const configAjax = {
            method: method,
            headers: new Headers(headers),
        };

        if (!isFile) {
            if (method !== 'GET') {
                configAjax['body'] = JSON.stringify(data);
            }
        }
        else {
            configAjax['body'] = data;
        }

        try {
            fetch(config.backendUrl + '/' + url, configAjax)
                .then(response => {
                    if (typeof response.status !== 'undefined') {

                        if (response.status === 500) {
                            if(showLoading) store.dispatch('hideLoading');

                            Toastify({
                                text: 'Ha ocurrido un error de comunicación, por favor, intente nuevamente',
                                duration: 3000,
                                close: true,
                                gravity: "top", // `top` or `bottom`
                                position: "center", // `left`, `center` or `right`
                                style: {
                                    background: '#ef5f5f',
                                }
                            }).showToast();
                        }
                        else {
                            return response.json();
                        }
                    }
                    else {
                        if(showLoading) store.dispatch('hideLoading');
                        onError(data);
                    }
                })
                .then(data => {
                    if (typeof data.status !== 'undefined') {
                        if (data.status) {
                            if (typeof onSuccess === 'function') {
                                onSuccess(data);
                            }
                            if(showLoading) store.dispatch('hideLoading');
                        }
                        else {
                            if(showLoading) store.dispatch('hideLoading');
                            if (typeof onError === 'function') {
                                onError(data);
                            }
                        }
                    }
                })
                .catch((e) => {
                    if(showLoading) store.dispatch('hideLoading');
                    if (typeof onError === 'function') {
                        onError(data);
                    }
                    console.log('API-RequestError:'+ e);
                });
        }
        catch (e) {
            if(showLoading) store.dispatch('hideLoading');
            if (typeof onError === 'function') {
                onError(data);
            }
        }
    },
    alert(text, type) {

        if (!type) type = 'info';

        if (type === 'success') {
            type = "#7cba7c";
        }
        else if (type === 'danger') {
            type = "#ef5f5f";
        }
        else {
            type = "#5dcaed";
        }

        if (text) {
            Toastify({
                text: text,
                duration: 4000,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                style: {
                    background: type,
                }
            }).showToast();
        }
    },
    confirm(title, onSuccess) {
        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
            }
        })
    },
    prepareForTable(data) {
        const dataArray = [];
        Object.keys(data).map(function (a){
            dataArray[a] = data[a];
        });
        return dataArray;
    },
    isEmpty(value) {
        if (value === '') {
            return true;
        }
        else {
            return false;
        }
    },
    copyToClipboard(text) {
        var dummy = document.createElement("textarea");
        // to avoid breaking orgain page when copying more words
        // cant copy when adding below this code
        // dummy.style.display = 'none'
        document.body.appendChild(dummy);
        //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.alert('Copiado a portapapeles', 'success');
    }
}
